<template>
  <b-modal
    id=""
    v-model="isShow"
    centered
    size="lg"
    scrollable
    :title="'考试详情'"
    title-class="font-18"
    hide-footer
  >
    <div class="row">
      <div class="col-2">考试名称：</div>
      <div class="col-10">{{ examInfoData.ksmc }}</div>
    </div>
    <div class="row">
      <div class="col-2">考试类别：</div>
      <div class="col-10">{{ examInfoData.lbmc }}</div>
    </div>
    <div class="row">
      <div class="col-2">考试分类：</div>
      <div class="col-10">{{ examInfoData.flmc }}</div>
    </div>
    <div class="row">
      <div class="col-2">考试类型：</div>
      <div class="col-10">{{ examInfoData.kslxmc }}</div>
    </div>
    <div class="row">
      <div class="col-2">考试报名状态：</div>
      <div class="col-10">
        {{ examInfoData.ksbmzt == 1 ? "正常" : "不可用" }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">考试报名时间：</div>
      <div class="col-10">
        {{ examInfoData.bmkssj }} - {{ examInfoData.bmjssj }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">考试缴费时间：</div>
      <div class="col-10">
        {{ examInfoData.jfkssj }} - {{ examInfoData.jfjssj }}
      </div>
    </div>
    <div class="row">
      <div class="col-2">考试时间：</div>
      <div class="col-10">
        {{ examInfoData.kskssj }} - {{ examInfoData.ksjssj }}
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  components: {},
  props: {
    examInfoData: {
      type: Object,
      default: {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShow: {
      get() {
        return  this.show;
      },
      // setter
      set(newValue) {
        
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
  created() {
    let data = this.examInfoData;
    for (const key in data) {
      if (key.slice(-2) == "sj") {
        this.examInfoData[key] = this.$moment(data[key]).format("YYYY-MM-DD");
      }
    }
  },
};
</script>
