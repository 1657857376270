<style type="text/css">
@import "~@/assets/css/common.css";
.f-column {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
td .btn {
  min-width: 85px;
  margin-right: 10px;
}
td .btn:last-child {
  margin-right: unset;
}
.el-popover {
  height: 200px;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import examInfo from "@/components/examInfo";
import applyProgress from "@/components/examInfo/applyProgress";
import {
  getUserExamList,
  getExamList,
  deleteApply,
  getExamInfo,
  userSpecial,
} from "@/api/examination.js";
import { format } from "@/libs/common";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    examInfo,
    applyProgress,
  },
  data() {
    return {
      title: "我的报名记录",
      items: [
        {
          text: "考试报名",
        },
        {
          text: "报名记录",
          active: true,
        },
      ],
      showModel: false,
      defaultvalue: 1,
      currentpage: 1,
      examInfoData: {},
      recordList: [],
      pageNum: null,
      progressShow: false,
    };
  },
  methods: {
    getSpecial() {
      userSpecial().then((res) => {
        if (res.status) {
          let data = res.data;
          if (data && data.length) {
            this.recordList.forEach((item) => {
              let filterData = data.filter((v) => {
                return v.ksbmbh == item.ksbmbh;
              })[0];
              if (filterData) {
                if (
                  filterData.yxsj &&
                  new Date().getTime() <
                    new Date(filterData.yxsj.replace(/-/g, "/")).getTime()
                ) {
                  if (filterData.sfxgbm) {
                    filterData.canEdit = true;
                  }
                  if (filterData.sfjf) {
                    filterData.canPay = true;
                  }
                }
                for (const key in filterData) {
                  if (!item[key]) {
                    item[key] = filterData[key];
                  }
                }
                this.$forceUpdate();
              }
            });
          }
        }
      });
    },
    getUserExam() {
      getUserExamList().then((res) => {
        if (res.status) {
          let newTime = new Date().getTime();
          let data = res.data.sort((x, y) => {
            return (
              new Date(y.createTime.replace(/-/g, "/")).getTime() -
              new Date(x.createTime.replace(/-/g, "/")).getTime()
            );
          });
          data.forEach((item) => {
            let examData = item.examRecordBean;
            if (examData) {
              if (!item.sfzstj) {
                //未正式提交
                item["canDelete"] = true;
              }
              if (examData.sfxyzgjgsh) {
                //需要审核
                if (
                  item.zgshzt != 9 &&
                  !(item.zgshzt == 1 && item.zpshzt == 1)
                ) {
                  //报名未锁定并且资格审核和照片审核有一个未通过
                  item["canDelete"] = true;
                }
              } else {
                //不需要审核
                if (examData.sfxyjf) {
                  if (item.jfzt != 1 && item.zfzt != 2) {
                    item["canDelete"] = true;
                  }
                }
              }
              try {
                if (
                    new Date(examData.bmkssj.replace(/-/g, "/")).getTime() <
                    newTime &&
                    new Date(examData.bmjssj.replace(/-/g, "/")).getTime() > newTime
                ) {
                  item["canEdit"] = true;
                }
              }catch (e) {}
              try {
                if (
                    examData.jfkssj &&
                    examData.jfjssj &&
                    new Date(examData.jfkssj.replace(/-/g, "/")).getTime() <
                    newTime &&
                    new Date(examData.jfjssj.replace(/-/g, "/")).getTime() >
                    newTime &&
                    examData.sfxyjf
                ) {
                  item["canPay"] = true;
                }
              }catch (e){}
              try {
                if (
                    examData.zkzdykssj &&
                    examData.zkzdyjssj &&
                    new Date(examData.zkzdykssj.replace(/-/g, "/")).getTime() <
                    newTime &&
                    new Date(examData.zkzdyjssj.replace(/-/g, "/")).getTime() >
                    newTime
                ) {
                  item["canPrint"] = true;
                }
              }catch (e){}
            }
          });
          this.recordList = data;
          this.getSpecial();
        }
      });
    },
    infoShow(index) {
      let routeUrl = "";
      let data = this.recordList[index];
      if (data.examRecordBean.bmtzh) {
        routeUrl = this.$router.resolve({
          path: "/announcementDetails",
          query: {
            newsId: data.examRecordBean.bmtzh,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    repeal(index) {
      let _this = this;
      _this
        .$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let bmh = _this.recordList[index].bmh;
          deleteApply(bmh).then((res) => {
            if (res.status) {
              _this.$message({
                message: res.message,
                type: "success",
              });
              _this.getUserExam();
            }
          });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    GoOn(index) {
      let ksbmbh = this.recordList[index].ksbmbh;
      let bmh = this.recordList[index].bmh;
      this.$router.push({
        path: "./enrollForm",
        query: {
          ksbmbh: ksbmbh,
          bmh: bmh,
        },
      });
    },
    pay(index) {
      let ksbmbh = this.recordList[index].ksbmbh;
      let bmh = this.recordList[index].bmh;
      this.$router.push({
        path: "/enrollForm",
        query: {
          ksbmbh: ksbmbh,
          bmh: bmh,
        },
      });
    },
    GoPrint(index) {
      let ksbmbh = this.recordList[index].ksbmbh;
      let bmh = this.recordList[index].bmh;
      this.$router.push({
        path: "/ticketPrinting",
        query: {
          ksbmbh: ksbmbh,
          bmh: bmh,
        },
      });
    },
    goInfo(index) {
      let data = this.recordList[index];
      this.$router.push({
        path: "./applyInfo",
        query: {
          ksbmbh: data.ksbmbh,
          bmh: data.bmh,
        },
      });
    },
    openProgress(index) {
      let data = this.recordList[index];
      this.progressShow = true;
      if (data.jfzt == 1) {
        this.pageNum = 4;
        return;
      }
      if (data.zgshzt == 1) {
        this.pageNum = 3;
        return;
      }
      if (data.zgshzt != 0 && data.zgshzt != null) {
        this.pageNum = 2;
        return;
      }
      if (!data.sfzstj && data.zgshzt == 0) {
        this.pageNum = 1;
        return;
      }
    },
    closeProgress() {
      this.progressShow = false;
      console.log(111);
    },
  },
  created() {
    this.getUserExam();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2">以下是你的全部报名记录，如需新报名请点击</span>
              <router-link to="./recordList">
                <img
                  src="@/assets/images/person/if-plus-circle.png"
                  class="mr-2"
                /><button type="button" class="btn btn-info h30">
                  添加新的报名
                </button>
              </router-link>
            </h4>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%">序号</th>
                    <th>考试名称</th>
                    <th style="width: 10%">报名状态</th>
                    <th style="width: 8%">资格审核</th>
                    <th style="width: 18%">缴费状态</th>
                    <th style="width: 10%">主管部门电话</th>
                    <th style="width: 5%">详情</th>
                    <th style="width: 15%">操作</th>
                  </tr>
                </thead>
                <tbody v-for="(item, index) in recordList" :key="index">
                  <tr>
                    <th scope="row" rowspan="2">{{ index + 1 }}</th>
                    <td>
                      <a
                        href="javascript:void(0);"
                        @click="infoShow(index)"
                        class="blue-font"
                        >{{ item.ksmc }}</a
                      >
                    </td>
                    <td>
                      <!-- <el-popover
                        placement="top"
                        title="报名进度"
                        width="1200"
                        trigger="hover"
                      >
                        <applyProgress :data="item"></applyProgress> -->
                      <div
                        class="text-danger"
                        slot="reference"
                        v-if="item.jfzt == 9"
                      >
                        已退费
                      </div>
                      <div
                        class="text-success"
                        slot="reference"
                        v-if="item.zgshzt == 1 && item.jfzt == 2"
                      >
                        报名成功（免缴费）
                      </div>
                      <div
                        class="text-success"
                        slot="reference"
                        v-else-if="item.zgshzt == 1 && item.jfzt == 1"
                      >
                        报名成功（已缴费）
                      </div>
                      <div
                          class="text-secondary"
                          slot="reference"
                          v-else-if="!(item.examRecordBean||{}).sfxyzgjgsh && item.zgshzt == 1 && item.sfzstj"
                      >
                        已提交，待缴费
                      </div>
                      <div
                        class="text-secondary"
                        slot="reference"
                        v-else-if="item.zgshzt == 1 && item.jfzt == 0"
                      >
                        已通过，待缴费
                      </div>
                      <div
                        class="text-secondary"
                        slot="reference"
                        v-if="
                          (item.zgshzt == 0 || item.zgshzt == null) &&
                          item.sfzstj
                        "
                      >
                        已提交，待审核
                      </div>
                      <div
                        class="text-danger"
                        slot="reference"
                        v-if="item.zgshzt == 2"
                      >
                        审核未通过
                      </div>
                      <div
                        class="text-warning"
                        slot="reference"
                        v-if="item.zgshzt == 9"
                      >
                        已锁定
                      </div>
                      <div
                        class="text-secondary"
                        slot="reference"
                        v-if="
                          !item.sfzstj &&
                          (item.zgshzt == 0 || item.zgshzt == null)
                        "
                      >
                        未完成，请确认提交
                      </div>
                      <!-- </el-popover> -->
                    </td>
                    <td>
                      <div
                        class="text-secondary"
                        v-if="item.zgshzt == 0 || item.zgshzt == null"
                      >
                        待审核
                      </div>
                      <div
                          class="blue-font"
                          slot="reference"
                          v-if="!(item.examRecordBean||{}).sfxyzgjgsh && item.zgshzt == 1 && item.sfzstj"
                      >
                        不审核
                      </div>
                      <div class="blue-font" v-else-if="item.zgshzt == 1">
                        已通过
                      </div>
                      <div class="text-warning" v-if="item.zgshzt == 9">
                        已锁定
                      </div>
                      <div class="text-danger" v-if="item.zgshzt == 2">
                        未通过
                      </div>
                    </td>
                    <td>
                      <span
                        class="text-secondary"
                        v-if="item.jfzt == 0 || item.jfzt == null"
                      >
                        待缴费({{ item.yjfje / 100 }}元)
                        <span
                          v-if="item.examRecordBean && item.examRecordBean.sfjmks"
                          :class="{ 'text-danger': item.jmzt == 2 }"
                          >{{
                            item.jmzt == 0
                              ? "（减免申请未审核）"
                              : item.jmzt == 2
                              ? "（减免申请不通过）"
                              : ""
                          }}</span
                        >
                      </span>
                      <span class="text-primary" v-if="item.jfzt == 1"
                        >已缴费({{ item.sjfje / 100 }}元)</span
                      >
                      <span class="text-danger" v-if="item.jfzt == 9"
                        >已退费</span
                      >
                      <span class="text-primary" v-if="item.jfzt == 2"
                        >免缴费<span v-if="item.jmzt != -1"
                          >（减免申请）</span
                        ></span
                      >
                    </td>

                    <td>{{ item.zgdwdh }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-info btn-sm"
                        @click="goInfo(index)"
                      >
                        详情
                      </button>
                    </td>
                    <td>
                      <div class="f-column">
                        <button
                          type="button"
                          class="btn btn-secondary btn-sm"
                          v-if="item.zgshzt == 9"
                        >
                          禁止
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          v-if="item.canDelete && item.canEdit"
                          @click="repeal(index)"
                        >
                          删除
                        </button>
                        <!-- 未审核、未提交、报名时间内 -->
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          v-if="
                            (item.zgshzt == 0 || item.zgshzt == null) &&
                            item.canEdit &&
                            !item.sfzstj
                          "
                          @click="GoOn(index)"
                        >
                          确认提交
                        </button>
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          v-if="item.jfzt == 1 && item.canPrint"
                          @click="GoPrint(index)"
                        >
                          打印准考证
                        </button>
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          v-if="
                            item.zgshzt == 1 &&
                            item.jfzt == 0 &&
                            item.canPay &&
                            item.examRecordBean.sfxyjf
                          "
                          @click="pay(index)"
                        >
                          缴费
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="item.examRecordBean">
                    <td colspan="7">
                      <applyProgress :data="item"></applyProgress>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-if="recordList.length == 0"
              class="d-flex justify-content-center"
            >
              暂无报名记录
            </div>

            <div class="float-right">
              <!-- <b-pagination v-model="defaultvalue" :total-rows="50" :per-page="10" aria-controls="my-table">
              </b-pagination> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 报名进度弹窗 -->

    <el-dialog
      title="提示"
      :visible.sync="progressShow"
      :modal="false"
      width="50%"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <applyProgress :pageNum="pageNum"></applyProgress
    ></el-dialog>
    <!-- <b-modal
      id="applyProgress"
      v-model="progressShow"
      centered
      title="报名进度"
      size="xl"
      title-class="font-18"
      hide-footer
    >
      <applyProgress :pageNum="pageNum"></applyProgress>
    </b-modal> -->
    <!-- 报名进度弹窗结束 -->
    <!-- <examInfo :examInfoData="examInfoData" :show="showModel"></examInfo> -->
  </Layout>
</template>
